import _ from 'lodash';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './core/store/index.store';
import { createPinia, PiniaVuePlugin } from 'pinia'
import httpService from '@/core/plugins/httpService';
import 'croppie/croppie.css';
import './assets/variables.css';
import './core/integrations/intercom.js';
import './core/integrations/sentry';
import './core/integrations/statusPage';
import {Auth0Plugin, getInstance} from './modules/auth/auth';
import {authConfig} from '@/modules/auth/config';
import localUserStore from '@/modules/auth/store/localUser.store';
import Hotjar from 'vue-hotjar';

import './assets/main.css';
import './assets/buttons.css';
import './assets/bootstrap-grid.min.css';
import './assets/typography.css';
import './assets/inputs.css';

try {
  // tslint:disable-next-line
  const gitLog = _.get(require('../gitlog.json'), 'data');
  if (gitLog) {
    console.info('Build details:');
    gitLog.forEach((c: string) => console.info('●', c));
  }
} catch (err) {
  console.info('Git log is off. Enable by running gitlog.sh or versionlog.sh during build.');
}

Vue.config.productionTip = false;

if (process.env.VUE_APP_AUTH_DRIVER === 'local') {
  httpService.defaults.withCredentials = true;
}
if (process.env.VUE_APP_AUTH_DRIVER === 'auth0') {
  Vue.use(Auth0Plugin, {
    onRedirectCallback: async (appState: any) => {
      // Call the backend to get the user profile, make sure the user is member of accounts or create accounts for owner
      await getInstance().fetchToken();
      await localUserStore.profile().then(() => {
          localUserStore.intercomHashKey();
      });

      await localUserStore.userMemberships();

      router.push(
          appState && appState.targetUrl
              ? appState.targetUrl
              : window.location.pathname,
      );
    },
    ...authConfig,
  });
}

Vue.use(Hotjar, {
  id: '2134093',
  isProduction: process.env.VUE_APP_HOTJAR_PROD === 'true',
});

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

new Vue({
  router,
  pinia,
  store,
  render: (h) => h(App),
}).$mount('#app');


