import { render, staticRenderFns } from "./FacebookAdsEditorPreview.vue?vue&type=template&id=cc402c40&scoped=true&"
import script from "./FacebookAdsEditorPreview.vue?vue&type=script&setup=true&lang=ts&"
export * from "./FacebookAdsEditorPreview.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./FacebookAdsEditorPreview.vue?vue&type=style&index=0&id=cc402c40&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc402c40",
  null
  
)

export default component.exports