import { Component } from 'vue';
import { createPinia, setActivePinia } from 'pinia';

import { useFacebookCarouselStore, useFacebookPostStore, useFacebookStoryStore } from '@/modules/ads/store/facebook/facebook.store';
import { useInstagramCarouselAd, useInstagramPostAd, useInstagramStoryAd } from '@/modules/ads/store/instagram/instagram.store';
import { useGoogleDisplayStore } from './store/google/googleDisplayAd.store';
import { useGoogleDisplayGeneratedStore } from './store/google/googleDisplayGeneratedAd.store';
import { useGoogleSearchStore } from '@/modules/ads/store/google-search/googleSearch.store';
import { useLinkedInPostStore } from '@/modules/ads/store/linkedin/linkedIn.store';

import AdEditor from '@/modules/ads/pages/AdEditor.vue';
import FacebookCarouselView from '@/core/components/ads/facebook/FacebookCarouselView.vue';
import FacebookPostAdView from '@/core/components/ads/facebook/FacebookPostView.vue';
import FacebookStoryAdView from '@/core/components/ads/facebook/FacebookStoryView.vue';
import GoogleDisplayAdView from '@/core/components/ads/google/GoogleDisplayAdView.vue';
import GoogleDisplayBannerSet from '@/core/components/ads/google/GoogleDisplayBannerSet.vue';
import LinkedInAdInputs from '@/core/components/ads/linkedin/LinkedInAdInputs.vue';
import LinkedInAdView from '@/core/components/ads/linkedin/LinkedInPostView.vue';

import { AdType, Channel, AdValidation } from '@/modules/ads/models/ads.model';
import {
  facebookPostValidation, facebookCarouselValidation, facebookStoryValidation,
  instagramPostValidation, instagramCarouselValidation, instagramStoryValidation,
  linkedinPostValidation,
} from '@/core/validations/ads/channels';

const pinia = createPinia();
setActivePinia(pinia);

export default [
  {
    path: '/campaigns-overview/:campaignId/ads/:adTypeRouteSegment/create',
    name: 'create ad',
    component: AdEditor,
    props: true,
    meta: { pageTransition: { name: 'fade', mode: 'in-out' }},
  },
  {
    path: '/campaigns-overview/:campaignId/ads/:adTypeRouteSegment/:adId',
    name: 'edit ad',
    component: AdEditor,
    props: true,
    meta: { pageTransition: { name: 'fade', mode: 'in-out' }},
  },
];

const adType: AdType[] = [
  'FacebookPostAd', 'FacebookCarouselAd', 'FacebookStoryAd', 'InstagramPostAd', 'InstagramCarouselAd',
  'InstagramStoryAd', 'LinkedinPostAd', 'GoogleDisplayGeneratedAd', 'GoogleDisplayCustomAd',
  'GoogleSearchAd',
];

const adsConfig: AdConfig[] = [
  {
    adType: 'FacebookPostAd',
    channel: 'facebook',
    routeSegment: 'facebook-post-ad',
    store: useFacebookPostStore(),
    view: FacebookPostAdView,
    validations: facebookPostValidation,
    channelEquivalent: ['InstagramPostAd', 'LinkedinPostAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'FacebookCarouselAd',
    channel: 'facebook',
    routeSegment: 'facebook-carousel-ad',
    store: useFacebookCarouselStore(),
    view: FacebookCarouselView,
    validations: facebookCarouselValidation,
    channelEquivalent: ['InstagramCarouselAd'],
    singleMediaAd: false,
    canEditAndCopy: true,
  },
  {
    adType: 'FacebookStoryAd',
    channel: 'facebook',
    routeSegment: 'facebook-story-ad',
    store: useFacebookStoryStore(),
    view: FacebookStoryAdView,
    validations: facebookStoryValidation,
    channelEquivalent: ['InstagramStoryAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'InstagramPostAd',
    channel: 'instagram',
    routeSegment: 'instagram-post-ad',
    store: useInstagramPostAd(),
    validations: instagramPostValidation,
    channelEquivalent: ['FacebookPostAd', 'LinkedinPostAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'InstagramCarouselAd',
    channel: 'instagram',
    routeSegment: 'instagram-carousel-ad',
    store: useInstagramCarouselAd(),
    validations: instagramCarouselValidation,
    channelEquivalent: ['FacebookCarouselAd'],
    singleMediaAd: false,
    canEditAndCopy: true,
  },
  {
    adType: 'InstagramStoryAd',
    channel: 'instagram',
    routeSegment: 'instagram-story-ad',
    store: useInstagramStoryAd(),
    validations: instagramStoryValidation,
    channelEquivalent: ['FacebookStoryAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'GoogleDisplayCustomAd',
    channel: 'google',
    routeSegment: 'google-display-custom-ad',
    store: useGoogleDisplayStore(),
    view: GoogleDisplayAdView,
    channelEquivalent: [],
    singleMediaAd: true,
    canEditAndCopy: false,
    validations: {},
  },
  {
    adType: 'GoogleDisplayGeneratedAd',
    channel: 'google',
    routeSegment: 'google-display-generated-ad',
    store: useGoogleDisplayGeneratedStore(),
    view: GoogleDisplayBannerSet,
    channelEquivalent: [],
    singleMediaAd: true,
    canEditAndCopy: true,
    validations: {},
  },
  {
    adType: 'LinkedinPostAd',
    channel: 'linkedin',
    routeSegment: 'linkedin-post-ad',
    store: useLinkedInPostStore(),
    form: LinkedInAdInputs,
    view: LinkedInAdView,
    channelEquivalent: ['FacebookPostAd', 'InstagramPostAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
    validations: linkedinPostValidation,
  },
  {
    adType: 'GoogleSearchAd',
    channel: 'google-search',
    routeSegment: 'google-search-ad',
    store: useGoogleSearchStore(),
    channelEquivalent: [],
    singleMediaAd: true,
    canEditAndCopy: true,
    validations: {},
  },
];

function getAdConfig(adType: AdType): AdConfig {
  return adsConfig.find((ad) => ad.adType === adType)!;
}

function getAdConfigByRoute(routeSegment: string): AdConfig {
  return adsConfig.find((ad) => ad.routeSegment === routeSegment)!;
}

function getAdConfigGeneralAdType(channel: Channel, generalAdType: GeneralAdType): AdConfig {
  return adsConfig.find((ad) => ad.channel === channel && ad.adType?.includes(generalAdType))!;
}

export interface AdConfig {
  adType: AdType;
  channel: Channel;
  routeSegment: string;
  validations: AdValidation;
  store: any;
  form?: Component;
  view?: Component;
  preview?: Component;
  channelEquivalent: AdType[];
  singleMediaAd: boolean;
  canEditAndCopy: boolean;
}

type GeneralAdType = 'Post' | 'Story' | 'Carousel' | 'DisplayCustom' | 'DisplayGenerated' | 'Search';

export { getAdConfig, getAdConfigByRoute, getAdConfigGeneralAdType, adsConfig, adType, GeneralAdType };
