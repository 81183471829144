import { createPinia, setActivePinia } from 'pinia';
import {Component} from 'vue';

import facebookCarouselAdStore from './store/facebook/facebookCarouselAd.store';
import facebookPostAdStore from './store/facebook/facebookPostAd.store';
import facebookStoryAdStore from './store/facebook/facebookStoryAd.store';
import facebookCarouselCustomStore from './store/facebook/facebookCarouselCustom.store';
import facebookPostCustomStore from './store/facebook/facebookPostCustom.store';
import facebookStoryCustomStore from './store/facebook/facebookStoryCustom.store';
import googleBannerGeneratedAdCustomStore from './store/google/googleDisplayGeneratedCustom.store';
import googleDisplayAdStore from './store/google/googleDisplayAd.store';
import googleDisplayCustomStore from './store/google/googleDisplayCustom.store';
import googleDisplayGeneratedAdStore from './store/google/googleDisplayGeneratedAd.store';
import { useGoogleSearchDefaultStore } from '@/modules/template-ads/store/google-search/googleSearchAd.store';
import { useGoogleSearchCustomStore } from './store/google-search/googleSearchCustom.store';
import instagramCarouselAdStore from './store/instagram/instagramCarouselAd.store';
import instagramPostAdStore from './store/instagram/instagramPostAd.store';
import instagramStoryAdStore from './store/instagram/instagramStoryAd.store';
import linkedinPostAdStore from './store/linkedin/linkedinPostDefault.store';
import instagramCarouselCustomStore from './store/instagram/instagramCarouselCustom.store';
import instagramPostCustomStore from './store/instagram/instagramPostCustom.store';
import instagramStoryCustomStore from './store/instagram/instagramStoryCustom.store';
import linkedinPostCustomStore from './store/linkedin/linkedinPostCustom.store';

import FacebookCarouselView from '@/core/components/ads/facebook/FacebookCarouselView.vue';
import FacebookPostView from '@/core/components/ads/facebook/FacebookPostView.vue';
import FacebookStoryAdView from '@/core/components/ads/facebook/FacebookStoryView.vue';
import GoogleDisplayAdView from '@/core/components/ads/google/GoogleDisplayAdView.vue';
import GoogleDisplayBannerSet from '@/core/components/ads/google/GoogleDisplayBannerSet.vue';
import LinkedInAdInputs from '@/core/components/ads/linkedin/LinkedInAdInputs.vue'
import LinkedInAdView from '@/core/components/ads/linkedin/LinkedInPostView.vue';
import TemplateAdEditor from './pages/TemplateAdEditor.vue';

import {AdType, Channel, AdValidation} from './models/ads.model';
import {
  facebookPostValidation, facebookCarouselValidation, facebookStoryValidation,
  instagramPostValidation, instagramCarouselValidation, instagramStoryValidation,
  linkedinPostValidation,
} from '@/core/validations/ads/channels';

const pinia = createPinia();
setActivePinia(pinia);
const googleSearchAdStore = useGoogleSearchDefaultStore();
const googleSearchCustomStore = useGoogleSearchCustomStore();


export default [
  {
    path: '/templates/:templateType/:templateId/ads/:adTypeRouteSegment/create',
    name: 'create-template-ad',
    component: TemplateAdEditor,
    props: true,
    meta: {pageTransition: {name: 'fade', mode: 'in-out'}},
  },
  {
    path: '/templates/:templateType/:templateId/ads/:adTypeRouteSegment/:adId',
    name: 'edit-template-ad',
    component: TemplateAdEditor,
    props: true,
    meta: {pageTransition: {name: 'fade', mode: 'in-out'}},
  },
];

const defaultAdConfig: AdConfig[] = [
  {
    adType: 'FacebookPostAd',
    channel: 'facebook',
    routeSegment: 'facebook-post-template',
    store: facebookPostAdStore,
    view: FacebookPostView,
    validations: facebookPostValidation,
    channelEquivalent: ['InstagramPostAd', 'LinkedinPostAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'FacebookCarouselAd',
    channel: 'facebook',
    routeSegment: 'facebook-carousel-template',
    store: facebookCarouselAdStore,
    view: FacebookCarouselView,
    validations: facebookCarouselValidation,
    channelEquivalent: ['InstagramCarouselAd'],
    singleMediaAd: false,
    canEditAndCopy: true,
  },
  {
    adType: 'FacebookStoryAd',
    channel: 'facebook',
    routeSegment: 'facebook-story-template',
    store: facebookStoryAdStore,
    view: FacebookStoryAdView,
    validations: facebookStoryValidation,
    channelEquivalent: ['InstagramStoryAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'InstagramPostAd',
    channel: 'instagram',
    routeSegment: 'instagram-post-template',
    store: instagramPostAdStore,
    validations: instagramPostValidation,
    channelEquivalent: ['FacebookPostAd', 'LinkedinPostAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'InstagramCarouselAd',
    channel: 'instagram',
    routeSegment: 'instagram-carousel-template',
    store: instagramCarouselAdStore,
    validations: instagramCarouselValidation,
    channelEquivalent: ['FacebookCarouselAd'],
    singleMediaAd: false,
    canEditAndCopy: true,
  },
  {
    adType: 'InstagramStoryAd',
    channel: 'instagram',
    routeSegment: 'instagram-story-template',
    store: instagramStoryAdStore,
    validations: instagramStoryValidation,
    channelEquivalent: ['FacebookStoryAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'LinkedinPostAd',
    channel: 'linkedin',
    routeSegment: 'linkedin-post-template',
    store: linkedinPostAdStore,
    form: LinkedInAdInputs,
    view: LinkedInAdView,
    validations: linkedinPostValidation,
    channelEquivalent: ['FacebookPostAd', 'InstagramPostAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'GoogleDisplayGeneratedAd',
    channel: 'google',
    routeSegment: 'google-display-generated-template',
    store: googleDisplayGeneratedAdStore,
    view: GoogleDisplayBannerSet,
    validations: {},
    channelEquivalent: [],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'GoogleDisplayCustomAd',
    channel: 'google',
    routeSegment: 'google-display-custom-template',
    store: googleDisplayAdStore, 
    view: GoogleDisplayAdView,
    validations: {},
    channelEquivalent: [],
    singleMediaAd: true,
    canEditAndCopy: false,
  },
  {
    adType: 'GoogleSearchAd',
    channel: 'google-search',
    routeSegment: 'google-search-template',
    store: googleSearchAdStore,
    channelEquivalent: [],
    singleMediaAd: true,
    canEditAndCopy: true,
    validations: {},
  },
];

const customAdsConfig: AdConfig[] = [
  {
    adType: 'FacebookPostAd',
    channel: 'facebook',
    routeSegment: 'facebook-post-template',
    store: facebookPostCustomStore,
    view: FacebookPostView,
    validations: facebookPostValidation,
    channelEquivalent: ['InstagramPostAd', 'LinkedinPostAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'FacebookCarouselAd',
    channel: 'facebook',
    routeSegment: 'facebook-carousel-template',
    store: facebookCarouselCustomStore,
    view: FacebookCarouselView,
    validations: facebookCarouselValidation,
    channelEquivalent: ['InstagramCarouselAd'],
    singleMediaAd: false,
    canEditAndCopy: true,
  },
  {
    adType: 'FacebookStoryAd',
    channel: 'facebook',
    routeSegment: 'facebook-story-template',
    store: facebookStoryCustomStore,
    view: FacebookStoryAdView,
    validations: facebookStoryValidation,
    channelEquivalent: ['InstagramStoryAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  // to enable LinkedIn add 'LinkedinPostAd' to channelEquivalent array (CC)
  {
    adType: 'InstagramPostAd',
    channel: 'instagram',
    routeSegment: 'instagram-post-template',
    store: instagramPostCustomStore,
    validations: instagramPostValidation,
    channelEquivalent: ['FacebookPostAd', 'LinkedinPostAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'InstagramCarouselAd',
    channel: 'instagram',
    routeSegment: 'instagram-carousel-template',
    store: instagramCarouselCustomStore,
    validations: instagramCarouselValidation,
    channelEquivalent: ['FacebookCarouselAd'],
    singleMediaAd: false,
    canEditAndCopy: true,
  },
  {
    adType: 'InstagramStoryAd',
    channel: 'instagram',
    routeSegment: 'instagram-story-template',
    store: instagramStoryCustomStore,
    validations: instagramStoryValidation,
    channelEquivalent: ['FacebookStoryAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'LinkedinPostAd',
    channel: 'linkedin',
    routeSegment: 'linkedin-post-template',
    store: linkedinPostCustomStore,
    form: LinkedInAdInputs,
    view: LinkedInAdView,
    validations: linkedinPostValidation,
    channelEquivalent: ['FacebookPostAd', 'InstagramPostAd'],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'GoogleDisplayGeneratedAd',
    channel: 'google',
    routeSegment: 'google-display-generated-template',
    store: googleBannerGeneratedAdCustomStore,
    view: GoogleDisplayBannerSet, 
    validations: {},
    channelEquivalent: [],
    singleMediaAd: true,
    canEditAndCopy: true,
  },
  {
    adType: 'GoogleDisplayCustomAd',
    channel: 'google',
    routeSegment: 'google-display-custom-template',
    store: googleDisplayCustomStore,
    view: GoogleDisplayAdView,
    validations: {},
    channelEquivalent: [],
    singleMediaAd: true,
    canEditAndCopy: false,
  },
  {
    adType: 'GoogleSearchAd',
    channel: 'google-search',
    routeSegment: 'google-search-template',
    store: googleSearchCustomStore,
    channelEquivalent: [],
    singleMediaAd: true,
    canEditAndCopy: true,
    validations: {},
  },
];

function getDefaultAdConfig(adType: AdType): AdConfig {
  return defaultAdConfig.find((ad) => ad.adType === adType)!;
}

function getAdConfigGeneralAdType(channel: Channel, generalAdType: GeneralAdType): AdConfig {
  return defaultAdConfig.find((ad) => ad.channel === channel && ad.adType?.includes(generalAdType))!;
}

function getAdConfigByRoute(routeSegment: string): AdConfig {
  return defaultAdConfig.find((ad) => ad.routeSegment === routeSegment)!;
}

function getCustomAdConfig(adType: AdType | null): AdConfig {
  return customAdsConfig.find((ad) => ad.adType === adType)!;
}

function getCustomAdConfigByRoute(routeSegment: string): AdConfig {
  return customAdsConfig.find((ad) => ad.routeSegment === routeSegment)!;
}

function getCustomAdConfigGeneralAdType(channel: Channel, generalAdType: GeneralAdType): AdConfig {
  return customAdsConfig.find((ad) => ad.channel === channel && ad.adType?.includes(generalAdType))!;
}

export interface AdConfig {
  adType: AdType | null;
  channel: Channel;
  routeSegment: string;
  validations: AdValidation;
  store: any;
  form?: Component;
  view?: Component;
  preview?: Component;
  channelEquivalent: AdType[];
  singleMediaAd: boolean;
  canEditAndCopy: boolean;
}

type GeneralAdType = 'Post' | 'Story' | 'Carousel' | 'DisplayCustom' | 'DisplayGenerated' | 'Search';

export { getDefaultAdConfig, getAdConfigByRoute, getCustomAdConfig, getCustomAdConfigByRoute, getAdConfigGeneralAdType, getCustomAdConfigGeneralAdType, GeneralAdType };
